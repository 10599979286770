import { format } from 'date-fns';
import { dateTimeFormat } from '@common/constants';
import { IProductGroupChangesDto, IProductGroupDto, ISubmissionTaskDto } from '@common/features/submission/types';
import { getProductTypeLabel } from '@common/helpers';
import { ProductGroupTableColumnKeys } from './constants';
import { CellWithChanges } from '@common/components/CellWithChanges/CellWithChanges';

export const getProductGroupTableColumnsData = (changes?: IProductGroupChangesDto) => [
  { accessor: ProductGroupTableColumnKeys.productGroupName, columnName: 'Product group' },
  {
    accessor: ProductGroupTableColumnKeys.maHolder,
    columnName: 'MA Holder',
    cell: ({ getValue }: any) => <CellWithChanges value={getValue()} changeType={changes?.maHolderCompanyId.changeType} />,
  },
  {
    accessor: ProductGroupTableColumnKeys.mahName,
    columnName: 'MAH name',
    cell: ({ getValue }: any) => <CellWithChanges value={getValue()} changeType={changes?.maHolderCompanyName.changeType} />,
  },
  {
    accessor: ProductGroupTableColumnKeys.companyName,
    columnName: 'Company name',
    cell: ({ getValue }: any) => <>{getValue()}</>,
  },
  {
    accessor: ProductGroupTableColumnKeys.productType,
    columnName: 'Product Type',
    cell: ({ getValue }: any) => <CellWithChanges value={getValue()} changeType={changes?.productType.changeType} />,
  },
  { accessor: ProductGroupTableColumnKeys.submittedBy, columnName: 'Submitted by' },
  { accessor: ProductGroupTableColumnKeys.dateSubmitted, columnName: 'Date submitted' },
];

export const mapToProductGroupData = (
  submissionTask: ISubmissionTaskDto | undefined,
  productGroup: IProductGroupDto | undefined,
  productGroupChanges?: IProductGroupChangesDto,
) => {
  if (!submissionTask) {
    return [];
  }

  const maHolderCompanyId = productGroupChanges?.maHolderCompanyId.value ?? productGroup?.maHolderCompanyId;
  const companyName = productGroupChanges?.maHolderCompanyName.value ?? productGroup?.maHolderCompanyName;
  const productType = productGroupChanges?.productType.value ?? productGroup?.productType;

  const isMaHolderSameAsCompany = productGroup && productGroup.companyId === maHolderCompanyId;

  return [
    {
      [ProductGroupTableColumnKeys.productGroupName]: `${productGroup?.productGroupName} ${formatActiveIngredients(
        productGroup,
      )}`,
      [ProductGroupTableColumnKeys.maHolder]: isMaHolderSameAsCompany ? 'Yes' : 'No',
      [ProductGroupTableColumnKeys.mahName]: isMaHolderSameAsCompany ? '-' : companyName,
      [ProductGroupTableColumnKeys.companyName]: productGroup?.companyName,
      [ProductGroupTableColumnKeys.productType]: getProductTypeLabel(productType),
      [ProductGroupTableColumnKeys.submittedBy]: submissionTask.submittedByUser,
      [ProductGroupTableColumnKeys.dateSubmitted]: format(new Date(submissionTask.submittedAt), dateTimeFormat),
    },
  ];
};

const formatActiveIngredients = (productGroup: IProductGroupDto | undefined) => {
  const activeIngredients = productGroup?.substances.reduce((result, current, index) => {
    if (index === productGroup?.substances?.length - 1) {
      return result + `${current.name}`;
    }

    return result + `${current.name}, `;
  }, '');

  return `(${activeIngredients})`;
};
