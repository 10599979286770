import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@common/hooks/redux';
import { useFetchCompanyProducts } from './hooks/useFetchCompanyProducts';
import { useFetchProductSubmissionStatuses } from './hooks/useFetchProductSubmissionStatuses';
import { selectActiveCompany } from '@common/features/user/selectors';
import { Button, ButtonTypes, SearchInput } from '@common/components';
import DatapharmTable from '@components/DatapharmTable/DatapharmTable';
import { prepareProductsColumns, useProductColumnSorting } from './publishedProductListTableSetup';
import useAppNavigation from '../../routing/useAppNavigation';
import * as Styled from './styles';
import { PageTitle } from '@common/styles';
import { useSearch } from '@common/components/SearchInput/useSearch';
import { FilteringSection } from '@common/components/FiltersDropdown/styles';
import { CheckboxFilter, FiltersDropdown, YesNoFilter } from '@common/components/FiltersDropdown';
import { useProductsListFilters } from '@common/features/product/hooks/useProductsListFilters';
import { useUnknownProductGroup } from '../CreateSubmission/hooks/useUnknownProductGroup';
import UnknownProductGroupPopup from '../../components/Submissions/UnknownProductGroupPopup';
import { useInfiniteLoader } from '@hooks/useInfiniteLoader';
import theme from '@common/theme';
import { NotifyModal } from '@common/components/NotifyModal';
import { useProductUpdateInterception } from '../../features/productGroup/hooks/useProductUpdateInterception';
import { Id } from '@common/types';

const Products = () => {
  const { goToPublishedProductGroup, goToProductGroup } = useAppNavigation();
  const activeCompany = useAppSelector(selectActiveCompany);
  const { searchPhrase, displayPhrase, searchError, handleChangeSearch, handleClearSearch } = useSearch();
  const { recordLimit, increaseOffset, resetOffset, offset, initialRecordOffset } = useInfiniteLoader();
  const { sortingQuery, customSorting } = useProductColumnSorting();
  const { isUnknownGroupStatusOpen, handleShowUnknownGroup, handleCloseUnknownGroup, isProductGroupUnknown } =
    useUnknownProductGroup();

  const { showSubmissionLimitModal, closeSubmissionLimitModal, handleUpdateWithActiveSubmissions } =
    useProductUpdateInterception();

  const {
    filtersQueryString,
    filtersDropdownProps,
    blackTriangleFilterProps,
    legalCategoryFilterProps,
    productTypeFilterProps,
    productStatusFilterProps,
  } = useProductsListFilters();

  const { companyProducts, isFetchingCompanyProducts, totalCount, isLoadingCompanyProducts } = useFetchCompanyProducts(
    activeCompany?.id!,
    filtersQueryString,
    searchPhrase,
    sortingQuery,
    recordLimit,
    offset,
    offset === initialRecordOffset,
  );

  const { handleUpdate } = useFetchProductSubmissionStatuses();

  const [selectedGroupState, setSelectedGroupState] = useState({ visible: false, productGroupId: '', newSubmissionData: {} });
  const [draftSubmissionId, setDraftSubmissionId] = useState<number>();

  const gotoNewSubmission = () => {
    if (isProductGroupUnknown(selectedGroupState.productGroupId)) handleShowUnknownGroup();
    else handleUpdate(+selectedGroupState.productGroupId, selectedGroupState.newSubmissionData);
  };

  useEffect(() => {
    resetOffset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersQueryString, searchPhrase, sortingQuery]);

  const handleDetailsClick = (productGroupId: string) => {
    if (isProductGroupUnknown(productGroupId)) handleShowUnknownGroup();
    else goToPublishedProductGroup(productGroupId);
  };

  const handleUpdateClick = (productGroupId: number, newSubmissionData: any) => {
    const callbacks = {
      draftSubmissionCallback: (submissionId: number) => setDraftSubmissionId(submissionId),
      noActiveSubmissionCallback: () =>
        setSelectedGroupState({ visible: true, productGroupId: productGroupId.toString(), newSubmissionData: newSubmissionData }),
      activeSubmissionCallback: () => {},
    };
    handleUpdateWithActiveSubmissions({ productGroupId, callbacks });
  };

  const columns = prepareProductsColumns(handleUpdateClick, handleDetailsClick);

  const shouldShowResultsCount = !(isFetchingCompanyProducts || isLoadingCompanyProducts);
  const shouldShowLoadMoreButton =
    companyProducts.length !== totalCount &&
    !(offset === initialRecordOffset && (isFetchingCompanyProducts || isLoadingCompanyProducts));

  return (
    <Styled.ProductsWrap>
      <UnknownProductGroupPopup isOpen={isUnknownGroupStatusOpen} handleClose={handleCloseUnknownGroup} />
      <PageTitle>Products</PageTitle>
      <FilteringSection>
        <SearchInput
          id="allProductsSearch"
          name="Search"
          value={displayPhrase}
          onChange={handleChangeSearch}
          onClearInput={handleClearSearch}
          error={searchError}
        />
        <FiltersDropdown {...filtersDropdownProps}>
          <YesNoFilter {...blackTriangleFilterProps} />
          <CheckboxFilter {...legalCategoryFilterProps} />
          <CheckboxFilter {...productTypeFilterProps} />
          <CheckboxFilter {...productStatusFilterProps} />
        </FiltersDropdown>
      </FilteringSection>
      <Styled.TableWrapper>
        {shouldShowResultsCount && <Styled.ResultsCountText>{totalCount} search results</Styled.ResultsCountText>}
        <DatapharmTable
          documents={companyProducts}
          columns={columns}
          enableSorting={true}
          customSorting={customSorting}
          showLoader={(isFetchingCompanyProducts || isLoadingCompanyProducts) && offset === initialRecordOffset}
          data-testid="products-table"
        />
        {shouldShowLoadMoreButton && (
          <Styled.LoadMoreButtonWrapper>
            <Button
              color={theme.colors.mediumBlue}
              type={ButtonTypes.TEXT}
              onClick={increaseOffset}
              isLoading={isLoadingCompanyProducts || isFetchingCompanyProducts}
            >
              Load more
            </Button>
          </Styled.LoadMoreButtonWrapper>
        )}
      </Styled.TableWrapper>
      <NotifyModal
        isOpen={selectedGroupState.visible}
        onConfirm={() => {
          gotoNewSubmission();
        }}
        onClose={() => {
          setSelectedGroupState({ productGroupId: '', visible: false, newSubmissionData: {} });
        }}
        title="Confirm draft submission"
        width="700px"
      >
        <p>This action will create a draft submission, please confirm if you want to continue.</p>
      </NotifyModal>
      <NotifyModal cancelText="Close" isOpen={showSubmissionLimitModal} onClose={closeSubmissionLimitModal} width="32rem">
        <p>
          <b>
            An in progress submission already exists for this product group - You can request changes to this submission via the
            submissions screen.
          </b>
        </p>
      </NotifyModal>
      <NotifyModal
        isOpen={!!draftSubmissionId}
        onConfirm={() => {
          goToProductGroup(draftSubmissionId as Id);
        }}
        onClose={() => {
          setDraftSubmissionId(undefined);
        }}
        title="Existing draft submission"
        width="700px"
      >
        <p>You will be redirected to an existing draft submission, please confirm if you want to continue.</p>
      </NotifyModal>
    </Styled.ProductsWrap>
  );
};

export default Products;
