import { legalCategoriesCheckboxes } from '@common/components/LegalCategories/constants';
import { IDocumentRenameDto, IProduct, IProductMetadata } from './types';
import { ErrorLog, ICheckboxEntry, ISelectOption, Id, IsTruthySelectItemValue } from '@common/types';
import { mapActiveIngredients, prepareIsTruthyDropdownDefaultOption } from '@common/helpers';
import {
  IActiveIngredient,
  IDocumentDto,
  IProductDto,
  IActiveIngredientsDto,
  ProductState,
  ProductStatus,
} from '@common/features/submission/types';
import { productTypeSelectOptions } from '@common/constants';
import { IActiveIngredientsStrength } from '../../types';
import { IProductForm } from './hooks/useProductForm';
import { ISingleSelectOption } from '@common/components';
import { FieldErrors } from 'react-hook-form';

export const prepareAssociatedContent = (data: IProductForm) => {
  return data.associatedContent.map((ac) => ac.id);
};

export const prepareLegalCategories = (legalCategories: string[]) => {
  return legalCategories.map((item) => {
    return legalCategoriesCheckboxes.find(
      (checkboxItem) => checkboxItem.value.toLowerCase() === item.toLowerCase(),
    ) as ICheckboxEntry;
  });
};

export const prepareAtcCodes = (atcCodes: string[]) => {
  if (!atcCodes) {
    return [];
  }
  return atcCodes.map((item) => ({ code: item }));
};

const prepareDocumentRenames = ({ documents }: IProductForm, dirtyFields: any) => {
  const indices: number[] = [];
  dirtyFields?.documents?.forEach((d: any, i: number) => d.documentTitle && indices.push(i));

  return indices?.map((i) => ({ documentName: documents[i].documentTitle, documentId: documents[i].documentId }));
};

export const mapProductDtoToProduct = (
  productDto?: IProductDto,
  productType: string = '',
  activeIngredients: IActiveIngredient[] = [],
  productGroupName: string = '',
): IProduct => {
  return {
    title: productDto?.name,
    genericName: productGroupName,
    productType: productType,
    productForm: productDto?.productForm ?? '',
    extraInformation: productDto?.extraInformation ?? '',
    submissionId: '',
    productMetadata: {
      maNumbers: productDto?.maNumbers ?? [],
      activeIngredientsMetadata: mapActiveIngredients(activeIngredients),
      legalCategories: productDto?.legalCategories ?? [],
      hasBlackTriangle: prepareIsTruthyDropdownDefaultOption(productDto?.hasBlackTriangle ?? null),
      isGlutenFree: prepareIsTruthyDropdownDefaultOption(productDto?.hasBlackTriangle ?? null),
      isLactoseFree: prepareIsTruthyDropdownDefaultOption(productDto?.isLactoseFree ?? null),
      isSuitableForVegans: prepareIsTruthyDropdownDefaultOption(productDto?.isSuitableForVegans ?? null),
      isPregnancyRisk: prepareIsTruthyDropdownDefaultOption(productDto?.isPregnancyRisk ?? null),
    },
  };
};

export const prepareUpdateProductDetailsData = (
  metaData: IProductForm,
  dirtyFields: any,
  product: IProductDto,
): IProductMetadata => {
  const { maNumbers, noMaNumbers } = metaData.productDetails.maNumbersForm;
  const { atcCodes, noAtcCodes } = metaData.productDetails.atcCodesForm;
  const { ampId, noAmpId } = metaData.productDetails.ampIdForm;
  const { activeIngredientsStrength, extraInformation, productForm, regulatorApprovedName, name } = metaData.productInfo;

  const contactGroup = metaData.productDetails.contactGroupForm.contactGroup;

  const activeIngredients = prepareActiveIngredients(activeIngredientsStrength);

  const documentIds = prepareAssociatedContent(metaData);

  const documentRenames = prepareDocumentRenames(metaData, dirtyFields);

  return {
    maNumbers: noMaNumbers || maNumbers === null ? [] : maNumbers,
    legalCategories: metaData.productDetails.legalCategories.map((item) => item.value?.toString()!),
    hasBlackTriangle: (metaData?.productDetails.hasBlackTriangle as ISingleSelectOption).value === IsTruthySelectItemValue.Yes,
    isGlutenFree: prepareAllergenData(metaData?.productDetails.allergensAndPregnancyRisk?.isGlutenFree),
    isLactoseFree: prepareAllergenData(metaData?.productDetails.allergensAndPregnancyRisk?.isLactoseFree),
    isSuitableForVegans: prepareAllergenData(metaData?.productDetails.allergensAndPregnancyRisk?.isSuitableForVegans),
    isPregnancyRisk: prepareAllergenData(metaData?.productDetails.allergensAndPregnancyRisk?.isPregnancyRisk),
    atcCodes: noAtcCodes ? [] : atcCodes!.map((item) => item.code),
    ampId: noAmpId || ampId == null ? null : parseInt(ampId),
    extraInformation: extraInformation,
    productForm: productForm?.label,
    activeIngredients,
    name: product!.isNameSetByUser || dirtyFields.productInfo?.name ? name : null,
    regulatorApprovedName:
      product!.isRegulatorApprovedNameSetByUser || dirtyFields.productInfo?.regulatorApprovedName ? regulatorApprovedName : null,
    documentIds,
    documentRenames,
    contactGroup: contactGroup,
    discontinuationReasonId: metaData.productInfo.discontinuationReason?.id,
    discontinuationReason: metaData.productInfo.discontinuationReason?.value,
  };
};

const prepareAllergenData = (data?: ISelectOption | null) => {
  if (data?.value) {
    return data.value === IsTruthySelectItemValue.Yes;
  }

  return null;
};

export const prepareActiveIngredients = (activeIngredientsStrength: IActiveIngredientsStrength): IActiveIngredientsDto => {
  const numerators = activeIngredientsStrength?.ingredientsWithNumerators.map((item) => ({
    name: item.name,
    value: parseFloat(item.numeratorValue),
    unit: item.numeratorUnit.label,
  }));

  const denominator = activeIngredientsStrength?.denominator.denominatorValue
    ? {
        value: parseFloat(activeIngredientsStrength.denominator.denominatorValue!),
        unit: activeIngredientsStrength.denominator.denominatorUnit?.label as string,
      }
    : null;

  return { numerators, denominator };
};

export const getSingleProduct = (productId: Id, dataSource?: IProductDto[]) =>
  dataSource?.find((p) => p.productId.toString() === productId);

export const isProductDiscontinued = (product: IProductDto) => product?.state === ProductState.Discontinued;
export const isProductRetired = (product: IProductDto) =>
  product?.status === ProductStatus.Retired || product?.state === ProductState.Retired;

export const getProductType = (productType?: string) =>
  productTypeSelectOptions.find((o) => o.value === productType)?.label.toString() ?? '';

export const getDocumentAfterSelectionChanged = (
  dataSource?: IDocumentDto[],
  documentIdAfterSelectionChanged?: number,
): IDocumentDto | undefined => {
  return dataSource?.find((x) => x.documentId === documentIdAfterSelectionChanged);
};

export const getAssociatedProducts = (productId: Id, dataSource?: IProductDto[], linkedProductIds?: number[]) => {
  return (
    dataSource
      ?.filter((p) => linkedProductIds?.includes(p.productId) && p.productId.toString() !== productId)
      .map((x) => x.name) ?? []
  );
};

export const mapErrors = (errors: FieldErrors<IProductForm>) => {
  const productInfoErrors = [
    errors?.productInfo?.activeIngredientsStrength && { subError: 'Active ingredients strength', itemId: 1 },
    errors?.productInfo?.name && { subError: 'emc Display Name', itemId: 8 },
    errors?.productInfo?.regulatorApprovedName && { subError: 'Medicinal Display Name', itemId: 8 },
    errors?.productInfo?.extraInformation && { subError: 'Extra information', itemId: 9 },
  ].filter((x) => x) as ErrorLog[];

  const associatedContentErrors = [errors?.associatedContent && { subError: 'Missing associated content', itemId: 2 }].filter(
    (x) => x,
  ) as ErrorLog[];

  const productDetailsErrors = [
    errors?.productDetails?.maNumbersForm && { subError: 'MA Numbers', itemId: 3 },
    errors?.productDetails?.atcCodesForm && { subError: 'ATC Code', itemId: 4 },
    errors?.productDetails?.hasBlackTriangle && { subError: 'Black Triangle', itemId: 5 },
    errors?.productDetails?.legalCategories && { subError: 'Legal categories', itemId: 6 },
    errors?.productDetails?.ampIdForm && { subError: 'AMP id', itemId: 7 },
  ].filter((x) => x) as ErrorLog[];

  return { productInfoErrors, associatedContentErrors, productDetailsErrors };
};

export const getDocumentName = (documentId: Id, documents: IDocumentDto[], documentRenames: IDocumentRenameDto[]) =>
  documentRenames.find((d) => d.documentId === documentId)?.documentName ??
  documents.find((d) => d.documentId === documentId)?.documentTitle ??
  '';
