import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';

import { useUpdateDocument } from '../../components/DocumentContentDetails/hooks/useUpdateDocument';
import { useValidateData } from './hooks/useValidateData';

import SubmissionFlowHeader from '@common/components/SubmissionFlowHeader/SubmissionFlowHeader';
import SubmissionFlowFooter from '@common/components/SubmissionFlowFooter/SubmissionFlowFooter';
import { usePrepareContentDetailsForm } from '../../components/DocumentContentDetails/hooks/usePrepareContentDetailsForm';
import { ValidationError } from './ValidationError';
import { ContentDetailsFormField } from './types';
import { DocumentType } from '@common/types';

import * as Styled from './styles';
import Tabs from '@common/components/Tabs/Tabs';
import { DocumentContent } from '../../components/DocumentContentDetails/DocumentContent';
import { HeaderBreadcrumb, Link } from '@common/components/SubmissionFlowHeader/styles';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { DocumentVersionHistory } from '../../components/DocumentVersionHistory/DocumentVersionHistory';
import { documentTypeToCustomLabelMap } from '@common/constants';
import { IDocumentWithAttributes } from '@common/features/submission/types';
import { DocumentDetailsReadonlySections } from '../../components/DocumentContentDetails/types';
import React from 'react';
import { useGetAlternativeTextFile } from '@common/hooks/useGetAlternativeTextFile';

export interface IDocumentDetailsContent {
  showHistoryFiltering: boolean;
  submissionId: number;
  documentId: string;
  documentWithAttributes: IDocumentWithAttributes;
  contentType: DocumentType;
  contentTitle: string;
  originalFileName: string;
  url: string;
  productGroupName: string;
  version: number;
  documentReadonlySections?: DocumentDetailsReadonlySections;
  isCustomerApprovalRequired: boolean | null;
  isFormExtended: boolean;
  customerUrl: string | undefined;
  onCancel: () => void;
  onSuccess: () => void;
  onFileDownload?: () => void;
}

const DocumentDetailsContent = ({
  showHistoryFiltering,
  submissionId,
  documentId,
  documentWithAttributes,
  contentType,
  contentTitle,
  originalFileName,
  url,
  version,
  isFormExtended,
  isCustomerApprovalRequired,
  documentReadonlySections,
  productGroupName,
  customerUrl,
  onCancel,
  onSuccess,
  onFileDownload,
}: IDocumentDetailsContent) => {
  const { showVersionHistory } = useFeatureFlags();

  const { alternativeTextFileName } = useGetAlternativeTextFile(submissionId.toString(), documentId, documentWithAttributes.attributes);
  const { handleSubmit, methods } = usePrepareContentDetailsForm(
    isCustomerApprovalRequired,
    documentWithAttributes.document,
    customerUrl,
  );
  const [isValidationPopupOpen, setIsValidationPopupOpen] = useState(false);
  const [missingFields, setMissingFields] = useState<string[]>([]);
  const shouldAlternativeTextFileBeIncluded = methods.watch(ContentDetailsFormField.hasAlternativeTextFile);

  useEffect(() => {
    if (alternativeTextFileName !== undefined) {
      methods.setValue(ContentDetailsFormField.hasAlternativeTextFile, true);
    }
  }, [alternativeTextFileName, methods]);

  const excludedFieldsToValidate = [
    ContentDetailsFormField.details,
    ContentDetailsFormField.hasDigitalVersion,
    ContentDetailsFormField.documentTitle,
    ContentDetailsFormField.hasAlternativeTextFile,
    ContentDetailsFormField.alternativeTextFileName,
    ContentDetailsFormField.isCustomerApprovalRequired,
    ContentDetailsFormField.isVisibleToHCP,
  ];

  const noDatesDocumentTypes = [
    DocumentType.Rmm,
    DocumentType.Dhcp,
    DocumentType.UserManual,
    DocumentType.SafetyAlert,
    DocumentType.ProductInformation,
    DocumentType.AudioVideo,
    DocumentType.Audio,
    DocumentType.Video,
  ];

  const noAuthTypes = [
    DocumentType.AudioVideo,
    DocumentType.Audio,
    DocumentType.Video,
    DocumentType.SafetyAlert,
    DocumentType.ProductInformation,
  ];

  const contentDescriptionTypes = [
    DocumentType.AudioVideo,
    DocumentType.Audio,
    DocumentType.Video,
    DocumentType.Rmm,
    DocumentType.Dhcp,
  ];

  const hasHtml = [ContentDetailsFormField.contentDescription];

  if (!shouldAlternativeTextFileBeIncluded) {
    excludedFieldsToValidate.push(ContentDetailsFormField.alternativeTextFile);
  }

  if (contentType !== DocumentType.Pil) {
    excludedFieldsToValidate.push(ContentDetailsFormField.isCustomerApprovalRequired);
  }

  if (noDatesDocumentTypes.includes(contentType)) {
    excludedFieldsToValidate.push(ContentDetailsFormField.authorisedDate);
    excludedFieldsToValidate.push(ContentDetailsFormField.regulatorApprovalDate);
  }

  if (!contentDescriptionTypes.includes(contentType)) {
    excludedFieldsToValidate.push(ContentDetailsFormField.contentDescription);
  }

  if (!(documentWithAttributes.attributes.allowAdminWeblinkUpload || ([DocumentType.Rmm].includes(contentType) && url))) {
    excludedFieldsToValidate.push(ContentDetailsFormField.webLink);
  }

  if (![DocumentType.Audio, DocumentType.Video].includes(contentType) || !customerUrl) {
    excludedFieldsToValidate.push(ContentDetailsFormField.customerUrl);
  }

  if (!documentWithAttributes.attributes.allowHtmlContentUpload) {
    excludedFieldsToValidate.push(ContentDetailsFormField.htmlContent);
  }

  if (noAuthTypes.includes(contentType)) {
    excludedFieldsToValidate.push(ContentDetailsFormField.authorisedDate);
    excludedFieldsToValidate.push(ContentDetailsFormField.regulatorApprovalDate);
    excludedFieldsToValidate.push(ContentDetailsFormField.approvedByRegulator);
    excludedFieldsToValidate.push(ContentDetailsFormField.isRegulatorApproved);
  }

  const { validateData, clearValidationFields } = useValidateData(excludedFieldsToValidate, hasHtml);

  const { updateDocument } = useUpdateDocument(submissionId, isFormExtended, contentType);

  const handleSave = () => {
    handleSubmit((data) => {
      const validatedData = validateData(data);

      clearValidationFields(validatedData, !isFormExtended);

      if (validatedData.length) {
        setMissingFields(validatedData);
        return setIsValidationPopupOpen(true);
      }

      updateDocument(
        { documentId: parseInt(documentId!), documentType: contentType, ...data },
        {
          onSuccess: () => onSuccess(),
        },
      );
    })();
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleValidatePopupCancel = () => {
    setIsValidationPopupOpen(false);
  };

  const getTabs = () => {
    const items = [
      {
        label: `${documentTypeToCustomLabelMap[contentType]} Details`,
        content: (
          <DocumentContent
            documentTitle={contentTitle}
            documentReadonlySections={documentReadonlySections}
            version={version}
            fileName={originalFileName}
            url={url}
            customerUrl={customerUrl}
            htmlContent={documentWithAttributes.document?.htmlContent ?? ''}
            contentType={contentType}
            onFileOpen={onFileDownload}
            documentAttributes={documentWithAttributes.attributes}
          />
        ),
      },
    ];

    if (showVersionHistory) {
      items.push({
        label: `Version history`,
        content: (
          <DocumentVersionHistory
            documentId={documentWithAttributes.document?.documentId ?? 0}
            showHistoryFiltering={showHistoryFiltering}
          />
        ),
      });
    }

    return items;
  };

  const renderContent = () => {
    switch (contentType) {
      case DocumentType.Smpc:
      case DocumentType.Pil:
      case DocumentType.Rmm:
      case DocumentType.Dhcp:
      case DocumentType.UserManual:
      case DocumentType.SafetyAlert:
      case DocumentType.ProductInformation:
      case DocumentType.AudioVideo:
      case DocumentType.Audio:
      case DocumentType.Video:
        return <Tabs items={getTabs()} />;
      default:
        return <>Work in progress</>;
    }
  };

  return (
    <Styled.ContentDetailsWrap>
      <FormProvider {...methods}>
        <SubmissionFlowHeader title={productGroupName}>
          <HeaderBreadcrumb>
            <Link>Product Family</Link> &gt; <Link>Product Group</Link> &gt; Document details
          </HeaderBreadcrumb>
        </SubmissionFlowHeader>
        {renderContent()}
        <ValidationError
          missingFields={missingFields}
          isOpen={isValidationPopupOpen}
          contentType={contentType!}
          handleClose={handleValidatePopupCancel}
          handleConfirm={handleValidatePopupCancel}
        />
        <SubmissionFlowFooter onContinue={handleSave} continueText="Save changes" onCancel={handleCancel} />
      </FormProvider>
    </Styled.ContentDetailsWrap>
  );
};

export default DocumentDetailsContent;
