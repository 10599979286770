import { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useUploadFile } from '@hooks/useUploadFile';
import { useDeleteFile } from '@hooks/useDeleteFile';
import { FormFields } from '../../types';
import { abortUpload } from '@api/uploadFileWithProgression';
import { DocumentType } from '@common/types';
import { getIsFileValid, getCanUpdateAltText } from '@common/features/document/helpers';
import { AddModalOpenModes } from '../../AddDocumentModal';
import { IDocumentDto } from '@common/features/submission/types';
import { FileRoles } from '@common/features/document/types';
import { useReplaceAltText } from './useReplaceAltText';

interface IProps {
  contentType: DocumentType;
  methods: UseFormReturn<any>;
  taskId?: string;
  submissionId?: string;
  existingDocuments?: IDocumentDto[];
  fileRole: FileRoles;
}

export const useReplaceAlternativeTextFile = ({
  contentType,
  methods: { setValue, reset, getValues, handleSubmit },
  fileRole,
}: IProps) => {
  const handleRemove = () => {
    setValue(FormFields.file, undefined);
    abortUpload();
    if (!isLoading) {
      deleteFile(fileName);
    }
  };

  const { uploadFile, isLoading, uploadProgress, fileName, fileErrors, setFileErrors } = useUploadFile(handleRemove);
  const { deleteFile } = useDeleteFile();

  const { replaceAltText } = useReplaceAltText();

  const [isOpen, setIsOpen] = useState(false);
  const [isModalVariant, setIsModalVariant] = useState<AddModalOpenModes>(null);

  const handleFileSubmit = (files: FileList | null) => {
    const file = files?.item(0);
    if (file) {
      const errors = getIsFileValid(contentType, file, fileRole);
      if (errors.length > 0) setFileErrors(errors);
      else {
        setValue(FormFields.file, file);
        uploadFile({ file, documentType: contentType, fileRole });
      }
    }
  };

  const handleUploadClick = (submissionId: string, documentId: string) => {
    const errors = getCanUpdateAltText(getValues(FormFields.file));

    if (errors.length > 0) setFileErrors(errors);
    else {
      handleSubmit(() =>
        replaceAltText(
          {
            submissionId,
            documentId,
            fileName: fileName,
          },
          {
            onSuccess: () => {
              setIsOpen(false);
            },
          },
        ),
      )();
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
    reset({});
    abortUpload();
  };

  const clearErrors = () => setFileErrors([]);

  const handleOpenModal = () => {
    reset((fieldValues: any) => ({ ...fieldValues }));
    setIsOpen(true);
    setIsModalVariant('Update');
  };

  return {
    handleFileSubmit,
    handleUploadClick,
    handleCancel,
    handleRemove,
    handleOpenModal,
    clearErrors,
    fileErrors,
    isOpen,
    isModalVariant,
    isLoading,
    uploadProgress,
  };
};
