import React, { useState } from 'react';
import Header from '@common/components/Header/Header';
import Footer from '@common/components/Footer/Footer';
import { MainContainerWrap, Top, ContentWraper, DefaultContent, CustomContent } from './styles';
import { useLocation } from 'react-router';
import { routes } from '../../routing/routes';
import { ButtonTypes } from '@common/components/Button/types';
import { Button } from '@common/components';
import useAppNavigation from '../../routing/useAppNavigation';
import { globalRoutes } from '@common/routing/routes';
import GlobalSidebar from '@components/GlobalSidebar/GlobalSidebar';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { Icons } from '@common/icons';
import { ThirdPartyLinkWarning } from '@common/features/global/ThirdPartyLinkWarning/ThirdPartyLinkWarning';
import { selectUserEmail } from '@common/features/user/selectors';
import { useAppSelector } from '@common/hooks/redux';
import { selectConfiguration } from '@common/features/configuration/selectors';
import { NotifyModal } from '@common/components/NotifyModal';

const getEntries = (showAuditReports: boolean) => {
  var items = [
    { display: 'Dashboard', to: globalRoutes.home, getIcon: () => <Icons.Home /> },
    { display: 'Submissions', to: routes.submissions, getIcon: () => <Icons.Submissions /> },
    { display: 'All Products', to: routes.products, getIcon: () => <Icons.Products /> },
  ];

  if (showAuditReports) items.push({ display: 'Audit Reports', to: routes.audits, getIcon: () => <Icons.Audits /> });

  return items;
};

interface IMainContainer {
  children: string | JSX.Element | JSX.Element[];
}

const MainContainer = ({ children }: IMainContainer) => {
  const { pathname } = useLocation();
  const { goToCreateSubmission } = useAppNavigation();
  const { showAuditReports } = useFeatureFlags();
  const email = useAppSelector(selectUserEmail);
  const [showUpdateEmail, setShowUpdateEmail] = useState(email.trimEnd().length === 0);
  const configuration = useAppSelector(selectConfiguration);

  const hasCustomLayout =
    (pathname.startsWith(routes.submissions) && !pathname.endsWith(routes.submissions)) ||
    pathname.includes(routes.productGroups);

  const handleRedirect = () => {
    goToCreateSubmission();
  };

  return (
    <>
      {!hasCustomLayout ? (
        <MainContainerWrap>
          <Top>
            <GlobalSidebar entries={getEntries(showAuditReports)} />
            <ContentWraper>
              <Header>
                <Button text="Create Submission" type={ButtonTypes.PRIMARY} onClick={handleRedirect} />
              </Header>
              <DefaultContent>{children}</DefaultContent>
              {showUpdateEmail && (
                <NotifyModal
                  confirmText={'Confirm'}
                  onConfirm={() => setShowUpdateEmail(false)}
                  title="Please update your details"
                  isOpen={showUpdateEmail}
                  width="36rem"
                >
                  <p>
                    You don't have an email address associated to your account, please update your email using our&nbsp;
                    <a target="_blank" rel="noreferrer" href={configuration.cmsLink}>Contact Management System (CMS)</a>
                  </p>
                </NotifyModal>
              )}
            </ContentWraper>
          </Top>
          <Footer />
        </MainContainerWrap>
      ) : (
        <MainContainerWrap>
          <Top>
            <ContentWraper>
              <CustomContent>{children}</CustomContent>
            </ContentWraper>
          </Top>
        </MainContainerWrap>
      )}
      <ThirdPartyLinkWarning />
    </>
  );
};

export default MainContainer;
